import React from 'react'
import PropTypes from 'prop-types'

const Financial = ({ color = 'none', background = 'none' }) => (
  <svg width='32' height='32' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <rect fill={background} width='32' height='32' rx='4' />
      <g fill={color} fillRule='nonzero'>
        <path d='M24.914 13.321a.61.61 0 00.61-.61v-1.22a.61.61 0 00-.324-.539l-9.151-4.88a.614.614 0 00-.575 0l-9.152 4.88a.61.61 0 00-.322.539v1.22c0 .337.273.61.61.61h.61v8.542c-.672 0-1.22.547-1.22 1.22v1.83c0 .337.273.61.61.61h18.304a.61.61 0 00.61-.61v-1.83c0-.673-.548-1.22-1.22-1.22v-8.542h.61zm-.61 10.983H7.22v-1.22h17.084v1.22zM8.44 21.863v-8.542h1.22v8.542H8.44zm2.441 0v-8.542h9.762v8.542H10.88zm10.982 0v-8.542h1.22v8.542h-1.22zM7.22 12.101v-.244L15.762 7.3l8.542 4.556v.244H7.22z' />
        <path d='M16 16.816a.625.625 0 01-.635-.612c0-.337.285-.612.635-.612h1.27c.35 0 .635-.274.635-.612a.624.624 0 00-.635-.613h-.635v-.612a.624.624 0 00-.635-.612.624.624 0 00-.635.612v.725c-.738.253-1.27.927-1.27 1.724 0 1.013.855 1.837 1.905 1.837.35 0 .635.275.635.612a.625.625 0 01-.635.612h-1.27a.624.624 0 00-.635.613c0 .338.285.612.635.612h.635v.612c0 .338.285.612.635.612.35 0 .635-.274.635-.612v-.725c.738-.253 1.27-.927 1.27-1.724 0-1.013-.855-1.837-1.905-1.837z' />
      </g>
    </g>
  </svg>
)

export default Financial

Financial.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
}
