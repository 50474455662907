import React from 'react'
import PropTypes from 'prop-types'

const AdminBoundaries = ({ color = 'none', background = 'none' }) => (
  <svg width='32' height='32' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <rect fill={background} width='32' height='32' rx='4' />
      <g fill={color} fillRule='nonzero'>
        <path d='M7.563 9.25A.563.563 0 017 8.687C7 7.758 7.757 7 8.688 7a.563.563 0 010 1.125.563.563 0 00-.563.563c0 .31-.252.562-.563.562zM24.438 9.25a.563.563 0 01-.563-.563.563.563 0 00-.563-.562.563.563 0 010-1.125C24.244 7 25 7.757 25 8.688c0 .31-.252.562-.563.562zM12.438 8.125h-1.125a.563.563 0 010-1.125h1.125a.563.563 0 010 1.125zM16.938 8.125h-1.876a.563.563 0 010-1.125h1.876a.563.563 0 010 1.125zM16.938 16.563h-1.876a.563.563 0 010-1.125h1.876a.563.563 0 010 1.124zM12.438 16.563h-1.125a.563.563 0 010-1.125h1.125a.563.563 0 010 1.124zM20.687 8.125h-1.125a.563.563 0 010-1.125h1.125a.563.563 0 010 1.125zM20.687 16.563h-1.125a.563.563 0 010-1.125h1.125a.563.563 0 010 1.124zM16 21.25a.563.563 0 01-.563-.563v-1.125a.563.563 0 011.126 0v1.125c0 .311-.252.563-.563.563zM16 13a.563.563 0 01-.563-.562v-1.125a.563.563 0 011.126 0v1.125c0 .31-.252.562-.563.562z' />
        <path d='M16 17.5a.563.563 0 01-.563-.562v-1.876a.563.563 0 011.126 0v1.876c0 .31-.252.562-.563.562zM16 9.25a.563.563 0 01-.563-.563V7.563a.563.563 0 011.126 0v1.125c0 .31-.252.562-.563.562zM7.563 21.25A.563.563 0 017 20.687v-1.125a.563.563 0 011.125 0v1.125c0 .311-.252.563-.563.563zM7.563 17.5A.563.563 0 017 16.938v-1.876a.563.563 0 011.125 0v1.876c0 .31-.252.562-.563.562zM7.563 13A.563.563 0 017 12.438v-1.125a.563.563 0 011.125 0v1.125c0 .31-.252.562-.563.562z' />
        <path d='M8.688 16.563H7.562a.563.563 0 010-1.125h1.125a.563.563 0 010 1.124zM24.438 21.25a.563.563 0 01-.563-.563v-1.125a.563.563 0 011.125 0v1.125c0 .311-.252.563-.563.563zM24.438 17.5a.563.563 0 01-.563-.562v-1.876a.563.563 0 011.125 0v1.876c0 .31-.252.562-.563.562zM24.438 13a.563.563 0 01-.563-.562v-1.125a.563.563 0 011.125 0v1.125c0 .31-.252.562-.563.562z' />
        <path d='M24.438 16.563h-1.125a.563.563 0 010-1.125h1.125a.563.563 0 010 1.124zM8.688 25A1.69 1.69 0 017 23.312a.563.563 0 011.125 0c0 .31.252.563.563.563a.563.563 0 010 1.125zM23.313 25a.563.563 0 010-1.125c.31 0 .562-.253.562-.563a.563.563 0 011.125 0A1.69 1.69 0 0123.312 25zM12.438 25h-1.125a.563.563 0 010-1.125h1.125a.563.563 0 010 1.125zM16.938 25h-1.876a.563.563 0 010-1.125h1.876a.563.563 0 010 1.125zM20.687 25h-1.125a.563.563 0 010-1.125h1.125a.563.563 0 010 1.125z' />
        <path d='M16 25a.563.563 0 01-.563-.563v-1.125a.563.563 0 011.126 0v1.125c0 .311-.252.563-.563.563z' />
      </g>
    </g>
  </svg>
)

export default AdminBoundaries

AdminBoundaries.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
}
