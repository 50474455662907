import React from 'react'
import PropTypes from 'prop-types'

const Energy = ({ color = 'none', background = 'none' }) => (
  <svg width='32' height='32' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <rect fill={background} width='32' height='32' rx='4' />
      <g fill={color} fillRule='nonzero'>
        <path d='M23.066 8.939A9.922 9.922 0 0016 6a9.926 9.926 0 00-7.071 2.934A9.96 9.96 0 006 16.018c0 2.585.986 5.035 2.738 6.887h-.863a.626.626 0 000 1.252h2.5c.345 0 .625-.28.625-.626v-2.505a.626.626 0 10-1.25 0v1.126a8.747 8.747 0 01-2.5-6.134c0-4.834 3.925-8.766 8.75-8.766 2.331 0 4.527.914 6.182 2.572a8.712 8.712 0 012.568 6.194c0 4.568-3.42 8.32-7.956 8.73a.152.152 0 01-.118-.04.154.154 0 01-.051-.117v-3.617a3.76 3.76 0 003.125-3.704v-2.505a.626.626 0 00-.625-.626H18.5v-1.878a.626.626 0 10-1.25 0v1.878h-2.5v-1.878a.626.626 0 10-1.25 0v1.878h-.625a.626.626 0 00-.625.626v2.505a3.76 3.76 0 003.125 3.704v3.617a1.407 1.407 0 001.531 1.403 9.963 9.963 0 006.459-3.2A9.998 9.998 0 0026 16.018a9.957 9.957 0 00-2.934-7.08zm-9.566 8.33v-1.878h5v1.879a2.505 2.505 0 01-2.5 2.504 2.505 2.505 0 01-2.5-2.504z' />
        <path d='M16 17a.667.667 0 11.002-1.335A.667.667 0 0116 17z' />
      </g>
    </g>
  </svg>
)

export default Energy

Energy.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
}
