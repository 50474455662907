import React from 'react'
import PropTypes from 'prop-types'

const Population = ({ color = 'none', background = 'none' }) => (
  <svg width='32' height='32' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <rect fill={background} width='32' height='32' rx='4' />
      <path
        d='M24.203 18.674c.277-.388.44-.86.44-1.369v-1.023c0-1.238-.96-2.26-2.186-2.38a2.584 2.584 0 00-1.377-1.118c.276-.389.439-.86.439-1.37v-1.022c0-1.32-1.09-2.392-2.43-2.392-1.338 0-2.428 1.073-2.428 2.392v1.023c0 .508.163.98.44 1.369a2.59 2.59 0 00-1.134.776 2.59 2.59 0 00-1.132-.776c.276-.389.439-.86.439-1.37v-1.022c0-1.32-1.09-2.392-2.43-2.392-1.338 0-2.428 1.073-2.428 2.392v1.023c0 .508.163.98.44 1.369-.583.2-1.07.601-1.378 1.118-1.226.12-2.188 1.141-2.188 2.38v1.023c0 .509.163.981.44 1.37C6.723 19.02 6 19.964 6 21.071v2.235h19.932v-2.235c0-1.107-.723-2.05-1.729-2.398zm-5.113.777a2.59 2.59 0 00-1.134-.777c.276-.388.44-.86.44-1.369v-1.023a2.39 2.39 0 00-1.346-2.14c.242-.204.557-.328.9-.328h2.28c.343 0 .658.124.9.328a2.39 2.39 0 00-1.345 2.14v1.023c0 .51.163.981.44 1.37a2.59 2.59 0 00-1.135.776zm-6.247 0a2.59 2.59 0 00-1.135-.777c.277-.388.44-.86.44-1.369v-1.023a2.39 2.39 0 00-1.344-2.139c.243-.205.557-.33.901-.33h2.28c.343 0 .657.125.9.329a2.39 2.39 0 00-1.348 2.14v1.023c0 .51.163.981.44 1.37a2.59 2.59 0 00-1.134.776zm4.362-2.146a1.23 1.23 0 01-1.239 1.22 1.23 1.23 0 01-1.238-1.22v-1.023c0-.672.555-1.22 1.238-1.22a1.23 1.23 0 011.239 1.22v1.023zm6.247-1.023v1.023a1.23 1.23 0 01-1.238 1.22 1.23 1.23 0 01-1.239-1.22v-1.023c0-.672.556-1.22 1.239-1.22a1.23 1.23 0 011.238 1.22zm-5.6-5.89c0-.673.555-1.22 1.238-1.22a1.23 1.23 0 011.238 1.22v1.023a1.23 1.23 0 01-1.238 1.219 1.23 1.23 0 01-1.238-1.22v-1.022zm-6.245 0c0-.673.555-1.22 1.238-1.22a1.23 1.23 0 011.238 1.22v1.023a1.23 1.23 0 01-1.238 1.219 1.23 1.23 0 01-1.238-1.22v-1.022zm-3.126 5.89c0-.672.555-1.22 1.238-1.22a1.23 1.23 0 011.238 1.22v1.023a1.23 1.23 0 01-1.238 1.22 1.23 1.23 0 01-1.238-1.22v-1.023zm3.766 5.853H7.191v-1.063a1.38 1.38 0 011.388-1.368h2.28a1.38 1.38 0 011.388 1.368v1.063zm6.248 0h-5.057v-1.063a1.38 1.38 0 011.389-1.368h2.279a1.38 1.38 0 011.389 1.368v1.063zm6.247 0h-5.057v-1.063a1.38 1.38 0 011.389-1.368h2.28a1.38 1.38 0 011.388 1.368v1.063z'
        fill={color}
        fillRule='nonzero'
      />
    </g>
  </svg>
)

export default Population

Population.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
}
