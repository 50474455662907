import React from 'react'
import PropTypes from 'prop-types'

const Transport = ({ color = 'none', background = 'none' }) => (
  <svg width='32' height='32' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <rect fill={background} width='32' height='32' rx='4' />
      <g fill={color} fillRule='nonzero'>
        <path d='M21 15.375a1.877 1.877 0 00-1.875 1.875c0 1.034.841 1.875 1.875 1.875a1.877 1.877 0 001.875-1.875A1.877 1.877 0 0021 15.375zm0 2.553a.679.679 0 11.002-1.358.679.679 0 01-.002 1.358z' />
        <path d='M21.175 13.341c-.611 0-1.192.131-1.716.367l-1.436-7.25A.568.568 0 0017.466 6H10.2a.568.568 0 00-.556.457L6.01 24.697a.568.568 0 00.557.678h14.53l.034-.002a.566.566 0 00.548-.308l2.195-4.306a4.178 4.178 0 001.5-3.217c0-2.316-1.884-4.2-4.2-4.2zM14.4 24.24v-4.276a.568.568 0 00-1.135 0v4.276H7.259l3.407-17.105h2.6V8.99a.568.568 0 001.135 0V7.135H17l1.432 7.228a4.192 4.192 0 00-1.458 3.179c0 1.25.545 2.416 1.5 3.217l1.774 3.48h-5.847zm8.671-4.291a.568.568 0 00-.154.188l-1.743 3.42-1.744-3.42a.568.568 0 00-.154-.188 3.05 3.05 0 01-1.168-2.407 3.069 3.069 0 013.066-3.065 3.069 3.069 0 013.065 3.065 3.05 3.05 0 01-1.168 2.407z' />
        <path d='M14.125 11.625c-.345 0-.625.267-.625.595v3.81c0 .328.28.595.625.595s.625-.267.625-.595v-3.81c0-.328-.28-.595-.625-.595z' />
      </g>
    </g>
  </svg>
)

export default Transport

Transport.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
}
