import React from 'react'
import PropTypes from 'prop-types'

const HHAsset = ({ color = 'none', background = 'none' }) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
  >
    <g fill='none' fillRule='evenodd'>
      <rect fill={background} width='32' height='32' rx='4' />
      <g fill={color} fillRule='nonzero'>
        <path d='M28.015 28.944h-2.049v1.056h-3.043v-1.056h-13.908v1.056h-3.043l0.001-1.056h-1.988c-1.098 0-1.987-0.89-1.987-1.987v-18.937c0-1.097 0.89-1.987 1.987-1.987h6.954c0 0 0.438-0.608 1.239-1.162l-2.553-2.554 0.703-0.702 2.755 2.754c0.524-0.225 1.131-0.385 1.83-0.385 0.743 0 1.373 0.183 1.91 0.43l2.799-2.799 0.702 0.702-2.614 2.615c0.729 0.537 1.115 1.102 1.115 1.102h9.189c1.098 0 1.987 0.89 1.987 1.987v18.937c0.001 1.097-0.889 1.986-1.986 1.986zM20.937 10.007c0-0.549-0.445-0.993-0.994-0.993h-13.908c-0.549 0-0.994 0.444-0.994 0.993v14.964c0 0.549 0.445 0.993 0.994 0.993h13.907c0.549 0 0.994-0.444 0.994-0.993v-14.964zM24.042 25.964h2.917v-0.993h-2.917v0.993zM24.042 23.977h2.917v-0.992h-2.917v0.992zM24.042 21.928h2.917v-0.993h-2.917v0.993zM24.042 19.941h2.917v-0.994h-2.917v0.994zM25.431 9.038c-0.822 0-1.49 0.667-1.49 1.49s0.668 1.49 1.49 1.49c0.823 0 1.49-0.667 1.49-1.49s-0.667-1.49-1.49-1.49zM25.431 13c-0.822 0-1.49 0.666-1.49 1.489s0.668 1.49 1.49 1.49c0.823 0 1.49-0.667 1.49-1.49s-0.667-1.489-1.49-1.489z' />
      </g>
    </g>
  </svg>
)

export default HHAsset

HHAsset.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
}
