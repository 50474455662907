import { AdminBoundaries } from '../../icons'

export const nigeriaMtfUiControls = [
  {
    id: 'admin',
    label: 'Module 1 – Household Energy Access',
    icon: AdminBoundaries,
    description: '',
    info: 'Administritative boundaries from the Government of Kenya.',
    controls: [
      {
        id: 'energy_access_summary',
        label: 'Energy Access Summary',
        defaultVisibility: false,
        legend: 'none',
        subcontrols: [
          {
            id: 'ng_primary_src_elec_by_tier',
            label: 'Primary Source of Electricity by Tier',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_primary_elec_src',
            label: 'Primary Source of Electricity',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_primary_cook_fuel_by_agg_cook_tier',
            label: 'Primary Source of Cooking Fuel by Aggregate Cooking Tier',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_primary_src_elec_lighting',
            label: 'Primary Source of Household Lighting',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
        ],
        info: '',
      },

      {
        id: 'stopgap_household_detail',
        label: 'Stopgap Household Detail',
        defaultVisibility: false,
        legend: 'none',
        subcontrols: [
          {
            id: 'ng_backup_lighting',
            label: 'Primary Source of Backup Lighting',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_kerosene_usage',
            label: 'Kerosene Usage per Week',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
        ],
      },
      {
        id: 'grid_hh_detail',
        label: 'Grid Household Detail',
        defaultVisibility: false,
        legend: 'none',
        subcontrols: [
          {
            id: 'ng_grid_elec_access_by_tier',
            label: 'Grid Electricity Access by Tier',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_access_any_elec',
            label: 'Access to Any Electricity',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_connected_natl_grid',
            label: 'Connected to National Grid',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
        ],
      },
      {
        id: 'cooking_fuel_detail',
        label: 'Cooking Fuel Detail',
        defaultVisibility: false,
        legend: 'none',
        subcontrols: [
          {
            id: 'ng_primary_stove',
            label: 'Primary Stove Type',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_primary_cook_fuel',
            label: 'Primary Source of Cooking Fuel',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_primary_cook_fuel_vs_cookstove',
            label: 'Primary Stove vs Primary Cooking Fuel',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_primary_secondary_fuels_heatmap',
            label: 'Primary vs Secondary Cooking Fuels',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
        ],
        info: '',
      },
    ],
  },
  {
    id: 'admin',
    label: 'Module 2 – Consumption & Economics',
    icon: AdminBoundaries,
    description: '',
    info: 'Administritative boundaries from the Government of Kenya.',
    controls: [
      {
        id: 'lighting_existing_expenditures',
        label: 'Existing expenditures on energy for lighting & appliances',
        defaultVisibility: false,
        legend: 'none',
        subcontrols: [
          {
            id: 'ng_num_days_lamp_used_per_month',
            label: 'Days Used Lamp or Candle per Month',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_weekly_lamp_expenses',
            label: 'Weekly Expenditure on Lamp or Candle',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_num_hours_per_aday_lamp_used',
            label: 'Hours Used Lamp or Candle per Day',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
        ],
        info: '',
      },
      {
        id: 'cooking_existing_expenditures',
        label: 'Existing expenditures on energy for cooking',
        defaultVisibility: false,
        legend: 'none',
        subcontrols: [
          {
            id: 'ng_primary_cooking_fuel_expense',
            label: 'Amount Spent on Fuel for Stoves',
            defaultVisibility: false,
            legend: 'none',
            info: '',
            link:
              'https://raw.githubusercontent.com/JosephSemrai/mtf-graph-test/main/Amount_Spent_on_Fuel_for_Stoves.js',
          },
          {
            id: 'ng_primary_cooking_stove_cost',
            label: 'Amount Paid for Cookstoves',
            defaultVisibility: false,
            legend: 'none',
            info: '',
            link:
              'https://raw.githubusercontent.com/JosephSemrai/mtf-graph-test/main/Amount_Paid_For_Cookstoves.js',
          },
        ],
        info: '',
      },
      {
        id: 'section_l_part_1',
        label: 'Consumption / expenditure on other goods & services',
        defaultVisibility: false,
        legend: 'none',
        subcontrols: [
          {
            id: 'ng_expected_monthly_electricity_charge',
            label: 'Electricity',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_mobile_phone_top_up_monthly_expense',
            label: 'Mobile Phone Top Up',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_monthly_rent',
            label: 'Rent',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_monthly_internet_expenditures',
            label: 'Internet',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },

          {
            id: 'ng_monthly_medical_expenditures',
            label: 'Medical',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_monthly_transportation_expenditures',
            label: 'Transportation',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_monthly_water_supply_expenditures',
            label: 'Water',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },

          {
            id: 'ng_monthly_cleaning_supplies_expenditures',
            label: 'Cleaning Supply',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_weekly_alcohol_tobacco_expenses',
            label: 'Alcohol and Tobacco',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_weekly_beverage_expenses',
            label: 'Beverages',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_weekly_cereal_expenses',
            label: 'Cereals',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_weekly_egg_meat_expenses',
            label: 'Egg and Meat',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_weekly_milk_product_expenses',
            label: 'Milk products',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_weekly_other_food_expenses',
            label: 'Other Foods',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_weekly_pulses_nuts_expenses',
            label: 'Pulses and Nuts',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_weekly_sugar_salt_expenses',
            label: 'Sugar and Salt',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_weekly_vegetable_expenses',
            label: 'Vegetables',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_yearly_clothing_expenses',
            label: 'Clothing',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_yearly_school_expenses',
            label: 'School',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
        ],
        info: '',
      },
      {
        id: 'section_2_part_2',
        label: 'Willingness to pay for energy',
        defaultVisibility: false,
        legend: 'none',
        subcontrols: [
          {
            id: 'ng_wtp_solar_upfront',
            label: 'Pay for Solar Home System Upfront',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_wtp_solar_reason',
            label: 'Reasons to Not Pay for Solar Home System Upfront',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_wtp_solar_12_months',
            label: 'Pay for Solar Home System Over 12 Months',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_wtp_solar_12m_reason',
            label: 'Reasons to Not Pay for Solar Home System Over 12 Months',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },

          {
            id: 'ng_wtp_grid_connection_upfront',
            label: 'Pay for Grid Connection Upfront',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_wtp_grid_connection_upfront_reasons',
            label: 'Reasons to Not Pay for Grid Connection Upfront',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_wtp_grid_connection_6_months',
            label: 'Pay for Grid Connection over 6 Months',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },

          {
            id: 'ng_wtp_grid_connection_6_months_reasons',
            label: 'Reasons to Not Pay for Grid Connection over 6 Months',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_wtp_fee_waived_grid_connection',
            label: 'Connect to Grid with Connection Fee Waived',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'ng_wtp_fee_waived_grid_connection_reasons',
            label: 'Reasons to Not Connect to Grid with Connection Fee Waived',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
        ],
        info: '',
      },
    ],
  },
  {
    id: 'admin',
    label: 'Module 3 – Financial Inclusion & ICT',
    icon: AdminBoundaries,
    description: '',
    info: 'Administritative boundaries from the Government of Kenya.',
    controls: [
      {
        id: 'Number_of_Phones_the_Household_Owns',
        label: 'Number of Phones the Household Owns',
        defaultVisibility: false,
        legend: 'none',
        info: '',
      },
      {
        id: 'Mobile_Money_Accounts',
        label: 'Mobile Money Accounts',
        defaultVisibility: false,
        legend: 'none',
        info: '',
      },
      {
        id: 'Mobile_Money_Payments',
        label: 'Mobile Money Payments',
        defaultVisibility: false,
        legend: 'none',
        info: '',
      },
      {
        id: 'Mobile_Account_Activity',
        label: 'Mobile Account Activity',
        defaultVisibility: false,
        legend: 'none',
        info: '',
      },
      {
        id: 'Mobile_Money_Service_Usage',
        label: 'Mobile Money Service Usage',
        defaultVisibility: false,
        legend: 'none',
        info: '',
      },
      {
        id: 'Electricity_Bill_Payments',
        label: 'Electricity Bill Payments',
        defaultVisibility: false,
        legend: 'none',
        info: '',
      },
      {
        id: 'Formal_Bank_Accounts',
        label: 'Formal Bank Accounts',
        defaultVisibility: false,
        legend: 'none',
        info: '',
      },
      {
        id: 'Types_of_Financial_Institutions',
        label: 'Types of Financial Institutions',
        defaultVisibility: false,
        legend: 'none',
        info: '',
      },
      {
        id: 'Sources_of_Loans_and_Credit',
        label: 'Sources of Loans and Credit',
        defaultVisibility: false,
        legend: 'none',
        info: '',
      },
    ],
  },
  {
    id: 'admin',
    label: 'Module 4 – Household Characteristics & Assets',
    icon: AdminBoundaries,
    description: '',
    info: 'Administritative boundaries from the Government of Kenya.',
    controls: [
      {
        id: 'dwelling_detail',
        label: 'Household Dwelling Detail',
        defaultVisibility: false,
        legend: 'none',
        subcontrols: [
          {
            id: 'Number_of_Rooms_the_Household_Occupies',
            label: 'Number of Rooms the Household Occupies',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'Wall_Dwelling_Materials',
            label: 'Wall Dwelling Materials',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'Floor_vs_Wall_Dwelling_Material',
            label: 'Floor vs Wall Dwelling Material',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'Household_Water_Quality',
            label: 'Household Water Quality',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
          {
            id: 'Total_Land_Size',
            label: 'Total Land Size',
            defaultVisibility: false,
            legend: 'none',
            info: '',
          },
        ],
      },
    ],
  },
]
